<template>
  <footer>
    <div class="copyRight">
      ©<span>{{ currentYear }}</span> {{t('copyright')}}

      <a href="https://profile.gigabyte.com/TermsOfUse.aspx" target="_blank">{{t('termsOfUse')}}</a>
      <span class="separate"> | </span>
      <a href="https://profile.gigabyte.com/PrivacyPolicy.aspx" target="_blank">{{t('privacyPolicy')}}</a>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'Footer',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style>
</style>