<template>
  <div class="body-content">
    <header>
      <div class="headerArea">
        <a class="logoLink" href="https://www.gigabyte.com/">
          <img id="original_logo_black" alt="GIGABYTE" title="GIGABYTE" src="@/assets/images/GigabyteLOGO_black.png" />
        </a>
      </div>
    </header>
    <main>
      <article>
        <div class="mainArea">
          <div class="BrowserList">
            <div class="BrowserItem">
              <svg class="svgItem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 425.2">
                <g class="cls-1">
                  <g id="Capa_1" data-name="Capa 1">
                    <path class="cls-2"
                      d="M367.24,362.48c15.28,0,22.73-13,9.46-35l-150.09-259a16.13,16.13,0,0,0-24.11,0L46.08,331.9c-11.36,17.61,1.36,30.58,11.67,30.58Z" />
                    <path class="cls-3"
                      d="M376.7,327.32,227.17,69.56c-6.1-6.62-11.2-8.32-16.8-5.6L353.48,312.88c13.28,22-.73,35-16,35H41.83c1.35,8.82,9.15,14.4,15.92,14.4H367.24C382.52,362.32,390,349.34,376.7,327.32Z" />
                    <path class="cls-4"
                      d="M336.41,344.49H88.62c-5.32,0-10.81-3.45-13.66-8.58-2-3.63-4.46-11.21,1.79-21.3L202.11,95.48l.2-.23a15.53,15.53,0,0,1,23.71,0l.21.24.16.28L346.55,311c6.15,10.59,7.53,19.95,3.87,26.32C347.76,341.94,342.79,344.49,336.41,344.49ZM206.92,99,81.82,317.63l0,.05c-3.48,5.59-4.08,11.05-1.68,15.36,1.81,3.26,5.31,5.55,8.5,5.55H336.41c2.95,0,6.9-.73,8.89-4.2,2.54-4.4,1.12-11.85-3.88-20.46l-120-215a9.62,9.62,0,0,0-14.48,0Z" />
                    <path class="cls-4"
                      d="M231.89,290.93a17.53,17.53,0,1,1-17.53-17.52A17.53,17.53,0,0,1,231.89,290.93Z" />
                    <polygon class="cls-4"
                      points="227.76 264.13 200.96 264.13 194.47 172.7 235.12 172.99 227.76 264.13" />
                    <g class="cls-5">
                      <path class="cls-6" d="M215.49,273.45v35a17.52,17.52,0,0,0,0-35Z" />
                      <polygon class="cls-6"
                        points="215.49 172.85 215.49 264.13 227.76 264.13 235.12 172.99 215.49 172.85" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="title">{{ t('title') }}</div>
          <div class="desc" v-html="t('desc')"></div>
        </div>
      </article>
    </main>
    <Footer></Footer>
  </div>

</template>

<script>
import { useI18n } from 'vue-i18n'
import Footer from './Footer.vue'
export default {
  name: 'Main',
  components: {
    Footer: Footer,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  }
}
</script>
<style scoped>
.svgItem {
  width: 12vw;
}

.svgItem .cls-1 {
  isolation: isolate;
}

.svgItem .cls-2 {
  fill: #ffd344;
}

.svgItem .cls-3 {
  fill: #e8c1a5;
}

.svgItem .cls-3,
.svgItem .cls-5 {
  mix-blend-mode: multiply;
}

.svgItem .cls-4 {
  fill: #323039;
}

.svgItem .cls-6 {
  fill: #b9b8b5;
}

@media screen and (max-width: 480px) {
  .svgItem {
    width: 100px;
  }
}
</style>